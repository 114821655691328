'use client';

import isSideBoxItemElement from '@haaretz/s-type-predicates/isSideBoxItemElement';
import React from 'react';
import s9 from 'style9';

import useSideBox from './SideBoxProvider';

import type { SideBoxItemFragment } from '@haaretz/s-fragments/SideBoxItem';

const ANIMATION_DELAY = 250;

// `c` is short for `classNames`
const c = s9.create({
  base: {
    transitionProperty: 'opacity',
    transitionDuration: `${ANIMATION_DELAY}ms`,
    transitionTimingFunction: 'ease-in-out',
    opacity: 0,
  },
  fadeIn: {
    opacity: 1,
  },
  hidden: {
    display: 'none',
  },
});
interface SideBoxElementProps {
  element: SideBoxItemFragment['element'];
  index: number;
}

export default function SideBoxElement({
  index,
  element,
  children,
}: React.PropsWithChildren<SideBoxElementProps>) {
  const { activeIndex } = useSideBox();
  const [visibilityStatus, setVisibilityStatus] = React.useState<
    'hidden' | 'beforeHidden' | 'beforeShow' | 'show'
  >(activeIndex === index ? 'show' : 'hidden');

  const currentShow = activeIndex === index;

  React.useEffect(() => {
    if (visibilityStatus === 'beforeShow') {
      const timeout = setTimeout(() => {
        // NOTE:  Removing stuck status after timeout cancelation
        setVisibilityStatus('hidden');
      }, ANIMATION_DELAY + 50);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    return undefined;
  }, [visibilityStatus]);

  React.useEffect(() => {
    if (currentShow) {
      let timeout: ReturnType<typeof setInterval> | undefined;

      if (visibilityStatus === 'beforeShow') {
        timeout = setTimeout(() => {
          setVisibilityStatus('show');
        }, ANIMATION_DELAY);
      } else if (visibilityStatus === 'hidden') {
        timeout = setTimeout(() => {
          setVisibilityStatus('beforeShow');
        }, ANIMATION_DELAY);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    } else if (visibilityStatus === 'show') {
      setVisibilityStatus('beforeHidden');
    }

    return undefined;
  }, [currentShow, visibilityStatus]);

  if (!isSideBoxItemElement(element)) return null;

  return (
    <div
      key={element.contentId}
      data-testid="side-box-item"
      className={s9(
        c.base,
        visibilityStatus === 'hidden' && c.hidden,
        visibilityStatus === 'show' && c.fadeIn
      )}
      onTransitionEnd={() => {
        if (visibilityStatus === 'beforeHidden') {
          setVisibilityStatus('hidden');
        }
      }}
    >
      {children}
    </div>
  );
}
